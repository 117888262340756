import React from "react"
import { Link } from "gatsby"

import styles from "./blog-item.module.css"

const BlogItem = ({ post }) => (
  <div className="blog-post-preview" key={post.id}>
    <h2 className={styles.date}>{post.frontmatter.date}</h2>
    <h1 className={styles.title}>
      <Link to={post.frontmatter.path}>{post.frontmatter.title}</Link>
    </h1>
    <p className={styles.excerpt}>{post.frontmatter.excerpt}</p>
  </div>
)

export default BlogItem