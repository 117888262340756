import React from "react"
import BlogItem from "./blog-item"
import styles from "./blog-list.module.css"

const BlogList = ({ posts }) => (
  <div className="container">
    {posts
      .filter(post => post.node.frontmatter.title.length > 0)
      .map(({ node: post }) => {
        return (<BlogItem post={post} />)
      })}
  </div>
)

export default BlogList